const clients = [
  {
    title: "The Ascott Limited",
    image: require("@/assets/clients/Ascott.png"),
  },
  {
    title: "Atkins",
    image: require("@/assets/clients/logo-atkins.png"),
  },
  {
    title: "Burj Al Arab",
    image: require("@/assets/clients/BAA.png"),
  },
  {
    title: "Banyan Tree",
    image: require("@/assets/clients/bayantree.png"),
  },
  {
    title: "Bareburger",
    image: require("@/assets/clients/bareburger.png"),
  },
  {
    title: "Carine",
    image: require("@/assets/clients/Carine.png"),
  },
  {
    title: "Cipriani",
    image: require("@/assets/clients/logo-cipriani.png"),
  },
  {
    title: "City Max",
    image: require("@/assets/clients/City-Masx.png"),
  },
  {
    title: "Class Pass",
    image: require("@/assets/clients/classpass.png"),
  },
  {
    title: "Costa",
    image: require("@/assets/clients/logo-costa.png"),
  },
  {
    title: "DCT",
    image: require("@/assets/clients/logo-dct_abu_dhabi.png"),
  },
  {
    title: "DHL",
    image: require("@/assets/clients/DHL.png"),
  },
  {
    title: "Dubai Golf",
    image: require("@/assets/clients/Dubai-Golf.png"),
  },
  {
    title: "Everything Retreat",
    image: require("@/assets/clients/everything-retreat.png"),
  },
  {
    title: "Gilpin",
    image: require("@/assets/clients/gilpin.png"),
  },
  {
    title: "Phitgood",
    image: require("@/assets/clients/logo-phitgoodco.png"),
  },
  {
    title: "Hakkasan Nightclub",
    image: require("@/assets/clients/Hakkassan.png"),
  },
  {
    title: "Hendrick's Gin",
    image: require("@/assets/clients/logo-hendricks.png"),
  },
  {
    title: "Heritage Store",
    image: require("@/assets/clients/Heritage-Store.png"),
  },
  {
    title: "Hyatt",
    image: require("@/assets/clients/logo-hyatt.png"),
  },
  {
    title: "Le Crans Hotel & Spa",
    image: require("@/assets/clients/Le-Crans.png"),
  },
  {
    title: "Lush",
    image: require("@/assets/clients/logo-lush.png"),
  },
  {
    title: "MisBahce",
    image: require("@/assets/clients/misbahce.jpg"),
  },
  {
    title: "Northcote",
    image: require("@/assets/clients/northcote.png"),
  },
  {
    title: "Peroni",
    image: require("@/assets/clients/logo-peroni.png"),
  },
  {
    title: "Riva",
    image: require("@/assets/clients/RIVA.png"),
  },
  {
    title: "Relais and Chateaux",
    image: require("@/assets/clients/Relais-and-Chateaux.png"),
  },
  {
    title: "Reebok",
    image: require("@/assets/clients/logo-rebok.png"),
  },
  {
    title: "Rosewood",
    image: require("@/assets/clients/Rosewood.png"),
  },
  {
    title: "Rotana",
    image: require("@/assets/clients/logo-rotana.png"),
  },
  {
    title: "Select Group",
    image: require("@/assets/clients/logo-select.png"),
  },
  {
    title: "Soneva",
    image: require("@/assets/clients/Soneva.png"),
  },
  {
    title: "Solaray",
    image: require("@/assets/clients/Solaray.png"),
  },
  {
    title: "Tourism Ireland",
    image: require("@/assets/clients/Tourism-Ireland.png"),
  },
  {
    title: "Vakkaru",
    image: require("@/assets/clients/Vakkaru.png"),
  },
  {
    title: "Voss",
    image: require("@/assets/clients/Voss.png"),
  },
  {
    title: "Yas Marina",
    image: require("@/assets/clients/logo-yas_marina.png"),
  },
];

export { clients };
