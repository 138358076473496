<template>
  <router-link
    :to="{
      name: 'project',
      params: {
        id: project.length - newest,
        name: project[project.length - newest].slug,
      },
    }"
    class="item"
  >
    <div
      class="image"
      :style="{
        'background-image':
          'url(' + project[project.length - newest].image + ')',
      }"
    />
    <div class="title">
      <h2>
        <span
          v-for="(item, id) in project[project.length - newest].title"
          :key="id"
          >{{ item.copy }}&nbsp;</span
        >
      </h2>
      <h4>{{ project[project.length - newest].category.join(" and ") }}</h4>
      <p>View Project</p>
    </div>
  </router-link>
</template>

<script>
import { projects } from "../data/projects.js";

export default {
  name: "ProjectHomeGridItem",
  data() {
    return {
      project: projects,
    };
  },
  props: {
    newest: Number,
  },
};
</script>
