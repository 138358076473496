var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"item",attrs:{"to":{
    name: 'project',
    params: {
      id: _vm.project.length - _vm.newest,
      name: _vm.project[_vm.project.length - _vm.newest].slug,
    },
  }}},[_c('div',{staticClass:"image",style:({
      'background-image':
        'url(' + _vm.project[_vm.project.length - _vm.newest].image + ')',
    })}),_c('div',{staticClass:"title"},[_c('h2',_vm._l((_vm.project[_vm.project.length - _vm.newest].title),function(item,id){return _c('span',{key:id},[_vm._v(_vm._s(item.copy)+" ")])}),0),_c('h4',[_vm._v(_vm._s(_vm.project[_vm.project.length - _vm.newest].category.join(" and ")))]),_c('p',[_vm._v("View Project")])])])}
var staticRenderFns = []

export { render, staticRenderFns }