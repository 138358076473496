<template>
  <div class="project-grid">
    <div class="left">
      <project-home-grid-item :newest="1" />
      <project-home-grid-item :newest="2" />
    </div>
    <div class="right">
      <div class="top">
        <project-home-grid-item :newest="3" />
        <project-home-grid-item :newest="4" />
      </div>
      <div class="bottom">
        <project-home-grid-item :newest="5" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectHomeGridItem from "./ProjectHomeGridItem.vue";

export default {
  components: {
    ProjectHomeGridItem,
  },
  name: "ProjectHomeGrid",
};
</script>
