<template>
  <v-main>
    <v-header
      id="web-header"
      :headerImage="require('@/assets/backgrounds/home-2.jpg')"
      :displayOverlay="false"
      :displayVideo="true"
      :isYoutube="true"
      videoSrc="https://q-comms.s3.eu-central-1.amazonaws.com/20230331-160411.mp4"
    />
    <v-header
      id="mobile-header"
      :headerImage="require('@/assets/backgrounds/home-2.jpg')"
      :displayOverlay="false"
      :displayVideo="true"
      :isYoutube="true"
      :videoSrc="require('@/assets/videos/qcomms_mobile.mp4')"
    />
    <container type="bg-dark" id="start">
      <v-row style="margin-top:120px; margin-bottom:100px;">
        <v-col class="text-center">
          <h1>Award Winning Communications Agency</h1>
          <p>
            Q Communications is an international communications agency, with bases 
            in London, Manchester, Dubai and Abu Dhabi. Q Communications specialises 
            in fully integrated travel, hospitality, beauty and wellness PR and digital 
            campaign strategies. We help to launch brands, building awareness, establishing 
            credibility and managing reputations throughout the United Kingdom & Ireland, 
            Europe, MENA and beyond.
          </p>
          <p>
            Q Communications can connect your brand with your audience through
            press coverage, social media, digital marketing, creative content,
            collaborations and influencer and celebrity endorsement.
          </p>
          <p>
            Whether you are looking to raise brand awareness, drive bookings or
            increase engagement, Q Communications can deliver game-changing
            results through a story telling narrative, making you part of the
            conversation by increasing media presence, delivering engaging
            social campaigns, putting your product in the right hands and by
            providing immersive brand experiences.
          </p>
          <br/>
          <strong> <p><em>“Your story is what you have, what you will always have. It is something to own.” </em> Michelle Obama
          </p></strong> 
          <p style="max-width:80%; margin:auto;"><strong><em>“I worked with Kate before she launched Q Communications UK on the opening of Hotel Football, 
          Café Football and Stock Exchange Hotel. She and her team achieved fantastic results, with widespread 
          global coverage and mass brand awareness through press and consumer relations, digital marketing and 
          social media activations. I would highly recommend her and the team at Q Communications.”</em></strong>
          </p>
          <br>
          <v-btn
            color="primary"
            title
            large
            class="my-5 mb-10"
            v-scroll-to="'#contact'"
            style="border-radius: 0;"
            >Get in touch with us</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="margin:auto; align-items:center; justify-content:center; margin-top:120px; margin-bottom-120px;">
        <h1>Our Services</h1>
      </v-row>
      <v-row style="margin:auto; align-items:center; justify-content:center">
        <v-col :sm="12" :md="3" v-for="(item, id) in services" :key="id">
          <v-card  tile :to="item.link">
            <v-img
              :src="item.image"
              gradient="to bottom, rgba(0,0,0,.77), rgba(0,0,0,.77)"
              class="white--text align-center"
              style="min-height:550px"
            >
              <v-card-title
                class="justify-center text-center text-uppercase font-weight-bold text-large-body-1"
                >{{ item.title }}</v-card-title
              >
              <v-card-text
                class="justify-center text-center text-sm-body-1"
                >{{ item.text }}</v-card-text
              >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </container>
    <container type="bg-light">
      <h1 class="text-center">Our Recent Projects</h1>
      <project-home-grid />
      <center>
        <v-btn tile large color="primary" class="my-10" to="/work"
          >View all of our work</v-btn
        >
      </center>
    </container>
    <container type="bg-dark">
      <h1 class="text-center" style="padding-bottom:80px; margin-top:120px;">Our Clients</h1>
      <v-row dense class="justify-center" style="margin-bottom:120px">
        <v-col
          v-for="(item, id) in client"
          :key="id"
          :xs="6"
          :sm="4"
          :md="3"
          :lg="2"
        >
          <v-card  style="box-shadow: none" class="clients" tile>
            <v-img  style="box-shadow: none" :src="item.image" :alt="item.title" contain height="80px" />
          </v-card>
        </v-col>
      </v-row>
    </container>
    <container type="bg-light">
      <v-row class="mt-16">
        <v-col :cols="12" :md="12">
          <h2 class="text-center">A Global Network</h2>
        </v-col>
      </v-row>
      <v-row dense class="mb-16">
        <v-col :sm="4">
          <v-card tile elevation="0" class="bg-transparent">
            <v-img src="@/assets/maps/MAP_UK.png" class="border-grey" />
            <v-card-text class="justify-center text-center"
              >United Kingdom</v-card-text
            >
          </v-card>
        </v-col>
        <v-col :sm="4">
          <v-card tile elevation="0" class="bg-transparent">
            <v-img src="@/assets/maps/MAP_UAE.png" class="border-grey" />
            <v-card-text class="justify-center text-center"
              >Middle East &amp; Africa</v-card-text
            >
          </v-card>
        </v-col>
        <v-col :sm="4">
          <v-card tile elevation="0" class="bg-transparent">
            <v-img src="@/assets/maps/MAP_RUSSIA_INDIA_CHINA.png" class="border-grey" />
            <v-card-text class="justify-center text-center"
              >Russia, Dach, India and China</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </container>
    <instagram-feed />
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import projectHomeGrid from "@/components/ProjectHomeGrid.vue";
import { clients } from "@/data/clients.js";
import InstagramFeed from "../components/InstagramFeed.vue";

export default {
  name: "Home",
  components: {
    vHeader,
    projectHomeGrid,
    InstagramFeed,
  },
  data() {
    return {
      client: clients,
      services: [
        {
          title: "Public Relations & Communications",
          text: "Develop media relations, conduct market research, manage media launches, provide all copy writing, press releases and blogs for the web, develop crisis comms strategies.",
          image: require("@/assets/images/about-05.jpg"),
          link: "/pr",
        },
        {
          title: "Digital & Social Media",
          text: "Create cut-through social media content and impactful campaigns. Q Communications manages your community, customer service, covers your photography and videography needs and provides detailed reports and ROI.",
          image: require("@/assets/images/social-02.jpg"),
          link: "/digital",
        },
        {
          title: "Influencer & Celebrity Endorsements",
          text: "Full C2C communication strategies and partnership management, allowing you to engage with millions of users and drive ROI through collaborations with YouTubers, Instagrammers and Social Influencers. Extensive relationships with A and B list celebrities to give your brand exposure on a global scale.",
          image: require("@/assets/images/influencer-02.jpg"),
          link: "/influencer",
        },
        {
          title: "Photo & Videography",
          text: "Curate premium branded content, bringing your campaigns to life. With a deep understanding of the local market, we ensure your videos and images engage, inspire and drive action.",
          image: require("@/assets/images/social-03.jpg"),
          link: "/photography",
        },
      ],
    };
  },
};
</script>
